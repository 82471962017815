.nav_list {
  margin-right: 1.5em;
  display: flex;
}

.nav_list-item {
  margin-left: 1.5em;
}

.app .nav_hamburger {
  display: none;
}

.nav_theme {
  margin-top: 0.4em;
}

.pl-1 {
  padding-left: 0.5em;
}

@media (max-width: 600px) {
  .nav_list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav_list-item {
    margin: 0.5em 0;
  }

  .app .nav_hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }

  .center nav {
    padding-right: 0.5em;
  }
}
