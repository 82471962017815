.about {
  flex-direction: column;
  margin-top: 3em;
}

.about_name {
  color: var(--clr-primary);
}

.about_role {
  margin-top: 1.2em;
}

.about_desc {
  font-size: 1rem;
  max-width: 600px;
}

.about_desc,
.about_contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

.headshot-img {
  border-radius: 50%;
  width: 200px;
  height: 220px;
  object-fit: cover;
  margin: 0 auto;
  margin-top: 1em;
  animation: fadeIn 4s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    text-align: center;
    margin-top: 2em;
  }

  .about_contact {
    margin: 0 auto;
    margin-top: 2.4em;
  }
}
