.projects_grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2em;
}

@media only screen and (max-width: 600px) {

  /*Big smartphones [426px -> 600px]*/
  .projects_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 2em;
  }
}
